<script setup lang="ts">
import { type SearchItems, slugify } from '../util'

/*
  Wikilinks follow the following basic syntax: [[(label:)searchterm(#fragment)( (style))]], with the parenthesized terms independently optional.

  Search terms (case insenstive, and leading 'the' are removed) can either be the title of a page or a defined tag.
  Tags can be a list, in which case they are aliases for the entire page, or an object, in which case they can contain shortcut links to headings on the page.
  If a tag object entry is '§', the key is used as the fragment (after slugification).
  If the tag entry is null or empty, it counts as an alias for the entire page.
  Tag entries can be a comma-separated list, in which case they all point to the same value (if '§', they all point to the first value in the list)
  Otherwise, the tag entry value itself is slugified.

  Example:
  ```
    title: foo
    tags:
      baz:
      lorem: §
      bar: quoo
      dave,lord dave: §
  ```

  [[foo]], [[the Foo]], and [[baz]] would all resolve to /foo. [[lorem]] would resolve to /foo#lorem, and [[bar]] would resolve to /foo#quoo. Both [[dave]] and [[lord dave]] resolve to /foo#dave

  Having to define these anchors manually is a lot safer for collisions than autogenerating them for every anchor.
  Regardless, it is always possible to simply do [[foo#ipsum]] if an alias is not defined.

  Wikilinks can also be DnDBeyond links, which takes the form [[ddb category search term]], for example [[ddb monster gelatinous cube]] or [[ddb spell wish]].
  DnDBeyond links can also be aliased, eg [[sold his soul for a d10 cantrip:ddb spell eldritch blast]], [[massive damage:ddb feat great weapon master]].

  If no `link` is given, it is interpreted as a same-page anchor link. They look like this: [[#Other Section]] or [[as previously discussed:#introduction]].
  The given fragment is slugified before being rendered, and no check is made to ensure it is valid, as there is no chance of a 404.

*/

const wikiLinkRegex = /^(?:([^#:(]+):?)*?([^#:(]+)?(?:#([^#:(]+))?(?: ?\(([^#:(]+)\))?$/s

type LinkData = {
  link: string
  label: string
  fragment: string
  classes: string
}

const ddbCategories: Record<string, string> = {
  monster: 'monsters',
  npc: 'monsters',
  creature: 'monsters',
  item: 'magic-item',
  spell: 'spells',
  feat: 'feats',
  race: 'races',
  class: 'classes',
}

const props = defineProps<{
  wiki: string
  labelOverride?: string
  icon?: string
  optional?: boolean
}>()

const linkData = computed<LinkData>(() => {
  const match = wikiLinkRegex.exec(props.wiki)
  if (!match) {
    console.log(`bad link: ${props.wiki}`)
    return { link: '', label: '', fragment: '', classes: '' }
  }
  const [, label, link, fragment, classes] = match
  return {
    link: link?.trim() || '#',
    label: label?.trim() || '',
    fragment: fragment?.trim() || '',
    classes: classes?.trim() || '',
  }
})

const isDDBLink = computed(() => linkData.value.link.startsWith('ddb '))
const isFragmentLink = computed(() => linkData.value.link === '#')
const label = computed(() => {
  const label = props.labelOverride || linkData.value.label
  if (label) {
    return label
  } else if (isDDBLink.value) {
    const parts = linkData.value.link.split(' ')
    const name = parts.slice(2).join(' ')
    return name.trim()
  } else if (isFragmentLink.value) {
    return linkData.value.fragment
  }
  return linkData.value.link
})

const searchTerm = slugify(linkData.value.link)

const dm = useDM()

const search: Ref<SearchItems> = inject('search', ref({} as SearchItems))

const target = computed(() => {
  const page = search.value[searchTerm]

  // bad link
  if (!page) return null

  // no access
  if (!(page.public || dm.value)) return null

  return page
})

const searchFragment = computed(() => {
  const tags = target.value?.tags
  if (!tags) return ''
  if (tags && tags[searchTerm] !== undefined) {
    const frag = tags[searchTerm]
    if (frag === null || frag === '') {
      // just an alias, go to top of target page
    } else if (frag === '§') {
      return slugify(searchTerm)
    } else {
      return slugify(frag)
    }
  }
  return ''
})

const fragment = computed(() => {
  const fragment = linkData.value.fragment || searchFragment.value
  if (fragment) {
    if (fragment.startsWith('#')) return fragment.trim()
    return '#' + fragment.trim()
  }
  return ''
})

const href = computed(() => {
  if (isDDBLink.value) {
    const parts = linkData.value.link.split(' ')
    const category = ddbCategories[parts[1]] || parts[1]
    const name = slugify(parts.slice(2).join('-'))
    const frag = fragment.value.replaceAll(' ', '-').toLowerCase()
    return `https://www.dndbeyond.com/${category}/${name}/${frag}`
  } else if (target.value) {
    return target.value._path! + fragment.value.replaceAll(' ', '-').toLowerCase()
  }
  return ''
})

const rarities = ['common', 'uncommon', 'rare', 'veryrare', 'legendary', 'paragon', 'artifact', 'paragonartifact']
const linkClasses = computed(() => {
  let rarityClasses: string[] = []
  if (rarities.some((r) => linkData.value.classes.includes(r))) {
    rarityClasses = ['rarity']
  }
  if (target.value && !linkData.value.label && target.value.rarity) {
    rarityClasses = ['rarity', target.value.rarity.replace(' ', '')]
  }
  return [linkData.value.classes, ...rarityClasses]
})
</script>

<template>
  <a v-if="isDDBLink" :href="href" target="_blank" rel="noopener noreferrer" :class="linkClasses" :data-label="label"
    ><Icon v-if="icon" :name="icon" />{{ label }}</a
  >
  <a
    v-else-if="isFragmentLink"
    :href="fragment.replaceAll(' ', '-').toLowerCase()"
    :class="linkClasses"
    :data-label="label"
    ><Icon v-if="icon" :name="icon" />{{ label }}</a
  >
  <NuxtLink v-else-if="target" :to="href" :class="linkClasses" :data-label="label" no-prefetch
    ><Icon v-if="icon" :name="icon" />{{ label }}</NuxtLink
  >
  <span v-else :class="optional ? '' : 'bad-link'" :data-label="label"
    ><Icon v-if="icon" :name="icon" />{{ label }}</span
  >
</template>

<style scoped lang="postcss">
a {
  .icon {
    margin-right: 0.5em;
  }
}
</style>
